import './image-gallery.scss';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function initSlider() {
  Swiper.use([Navigation]);

  new Swiper('.image-gallery-slider', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    navigation: {
      nextEl: '.image-gallery-slider .slider-navigation--next',
      prevEl: '.image-gallery-slider .slider-navigation--prev',
      disabledClass: 'hidden',
    },
    breakpoints: {
      1024: {
        slidesPerView: 2,
      },
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initSlider();
  },
  {
    once: true,
  }
);
