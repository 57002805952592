import './relevant-brands.scss';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function initSlider() {
  Swiper.use([Navigation]);

  new Swiper('.b-relevant-brands-slider', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: '.b-relevant-brands-slider .slider-navigation--next',
      prevEl: '.b-relevant-brands-slider .slider-navigation--prev',
      disabledClass: 'hidden',
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initSlider();
  },
  {
    once: true,
  }
);
