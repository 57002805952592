import './contact-form.scss';

const selectors: Record<
  'form' | 'field' | 'radio' | 'children' | 'itemRelatedTo',
  string
> = {
  form: '[data-contact-form]',
  field: '[data-contact-form-field]',
  radio: '[data-contact-form-radio]',
  children: '[data-contact-form-field-children]',
  itemRelatedTo: '[data-contact-form-item-related-to]',
};

const classes: Record<'isHidden', string> = {
  isHidden: 'hidden',
};

const init = () => {
  Array.from(document.querySelectorAll(selectors.form), (form) => {
    Array.from(
      // @ts-ignore
      form.querySelectorAll(selectors.radio) as HTMLInputElement[],
      (radio) => {
        if (radio.checked) {
          const childen = radio
            .closest(selectors.field)
            ?.querySelector(selectors.children) as HTMLElement | undefined;

          if (childen) {
            childen.classList.remove(classes.isHidden);
          }
        }
      }
    );

    Array.from(
      // @ts-ignore
      form.querySelectorAll(selectors.itemRelatedTo) as HTMLInputElement[],
      (itemTo) => {
        if (itemTo.checked) {
          const value = itemTo.value;
          const key = itemTo.getAttribute('data-contact-form-item-related-to');
          Array.from(
            // @ts-ignore
            form.elements as HTMLFormElement[],
            (formElement) => {
              if (key === formElement.name) {
                if (formElement.nodeName === 'SELECT') {
                  const options = Array.from(
                    formElement.querySelectorAll('option')
                  );

                  options.forEach((option) => option.removeAttribute('hidden'));

                  options.forEach((option) => {
                    if (
                      value !== option.getAttribute('data-key') &&
                      option.value !== ''
                    ) {
                      option.setAttribute('hidden', '');
                    }
                  });
                }
              }
            }
          );
        }
      }
    );

    form.addEventListener('change', (ev) => {
      const trigger = ev.target as HTMLElement;

      Array.from(
        //@ts-ignore
        form.elements as HTMLFormElement[],
        (element) => {
          if (element.hasAttribute('data-contact-form-radio')) {
            const childen = element
              .closest(selectors.field)
              ?.querySelector(selectors.children) as HTMLElement | undefined;

            if (childen) {
              // @ts-ignore
              childen.classList[!element.checked ? 'add' : 'remove'](
                classes.isHidden
              );
            }
          }

          if (trigger.hasAttribute('data-contact-form-item-related-to')) {
            const value = element.value;
            const key = element.getAttribute(
              'data-contact-form-item-related-to'
            );

            if (element.checked) {
              Array.from(
                // @ts-ignore
                form.elements as HTMLFormElement[],
                (formElement) => {
                  if (key === formElement.name) {
                    if (formElement.nodeName === 'SELECT') {
                      formElement.value = '';

                      const options = Array.from(
                        formElement.querySelectorAll('option')
                      );

                      options.forEach((option, index) => {
                        option.removeAttribute('hidden');
                      });

                      options.forEach((option) => {
                        if (
                          value !== option.getAttribute('data-key') &&
                          option.value !== ''
                        ) {
                          option.setAttribute('hidden', '');
                        }
                      });
                    }
                  }
                }
              );
            }
          }
        }
      );
    });
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
