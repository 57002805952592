const selectors: Record<'box' | 'content', string> = {
  box: '[data-box-to-edge]',
  content: '[data-box-to-edge-content]',
};

const init = () => {
  const boxes = Array.from(document.querySelectorAll(selectors.box));

  const changeBoxWidthOnResize = () => {
    const documentWidth = document.documentElement.clientWidth;

    boxes.forEach((box) => {
      const content = box.querySelector(selectors.content) as HTMLElement;
      const leftBoxEdge = box.getBoundingClientRect().left;

      if (content) {
        content.style.width = documentWidth
          ? documentWidth - leftBoxEdge + 'px'
          : '100%';
      }
    });
  };

  changeBoxWidthOnResize();
  window.addEventListener('resize', changeBoxWidthOnResize);
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
