import './image-grid.scss';
import tippy, { hideAll } from 'tippy.js';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const selectors: Record<'item' | 'marker' | 'popup' | 'point', string> = {
  item: '[data-popup-item]',
  marker: '[data-marker]',
  popup: '[data-popup]',
  point: '.image-marker',
};

const classes: Record<'active', string> = {
  active: 'active',
};

const elements: Record<'items', NodeListOf<HTMLElement> | null> = {
  items: null,
};

const init = () => {
  elements.items = document.querySelectorAll(selectors.item);

  Array.from(elements.items).forEach((item) => {
    const popup = item.querySelector(selectors.popup) as HTMLElement;
    const marker = item.querySelector(selectors.marker) as HTMLElement;
    const point = item.querySelector(selectors.point) as HTMLElement;
    const parentSlideItem = marker?.closest('.swiper-slide') as HTMLElement;

    if (!popup) return null;

    tippy(marker, {
      content: popup.innerHTML,
      allowHTML: true,
      trigger: 'click',
      placement: 'bottom',
      animation: 'scale',
      interactive: true,
      maxWidth: 500,
      onShow(instance) {
        point.classList.add(classes.active);
        hideAll({ exclude: instance });

        if (parentSlideItem) {
          parentSlideItem.style.zIndex = '9999999';
        }
      },
      onHide() {
        point.classList.remove(classes.active);

        if (parentSlideItem) {
          parentSlideItem.style.zIndex = '0';
        }
      },
    });
  });
};

function initSlider() {
  Swiper.use([Navigation]);

  new Swiper('.b-image-grid-slider', {
    slidesPerView: 'auto',
    spaceBetween: 16,
    navigation: {
      nextEl: '.b-image-grid-slider .slider-navigation--next',
      prevEl: '.b-image-grid-slider .slider-navigation--prev',
      disabledClass: 'hidden',
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initSlider();
    init();
  },
  { once: true }
);
