import './content-slider.scss';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function initSlider() {
  Swiper.use([Navigation]);

  const swiper = new Swiper('.b-content-slider-slider', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: '.b-content-slider-slider .b-content-slider__slider-button--next',
      prevEl: '.b-content-slider-slider .b-content-slider__slider-button--prev',
      disabledClass: 'hidden',
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initSlider();
  },
  { once: true }
);
