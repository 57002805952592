import './header-desktop.scss';

const selectors: Record<'header' | 'dropdown' | 'headerDesktopTop', any> = {
  header: '[data-header]',
  dropdown: '[data-dropdown]',
  headerDesktopTop: '[data-header-desktop-top]',
};

const classes: Record<'isActive', string> = {
  isActive: 'active',
};

const init = () => {
  const header = document.querySelector(selectors.header);
  const headerDesktopTop = document.querySelector(selectors.headerDesktopTop);

  if (!header) return null;

  const dropdowns = header.querySelectorAll(
    selectors.dropdown
  ) as HTMLElement[];
  document.documentElement.style.setProperty(
    '--header-sticky-top',
    -headerDesktopTop.getBoundingClientRect().height + 'px'
  );

  window.addEventListener('scroll', (ev) => {
    if (window.scrollY >= headerDesktopTop.getBoundingClientRect().height) {
      dropdowns.forEach((dropdown) => {
        const trigger = dropdown.querySelector(
          '[data-dropdown-trigger]'
        ) as HTMLElement;

        trigger.classList.remove(classes.isActive);
        dropdown.classList.remove(classes.isActive);
      });
    }
  });

  new ResizeObserver(() => {
    document.documentElement.style.setProperty(
      '--header-sticky-top',
      -headerDesktopTop.getBoundingClientRect().height + 'px'
    );
  }).observe(header);
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
