const observeStyle = (
  target: HTMLElement,
  property: string,
  callback: (data: any) => void,
  initialValue: string = ''
) => {
  let frameId: number = NaN;
  let value: string | undefined = undefined;

  const css = getComputedStyle(target);

  const observer = () => {
    frameId = requestAnimationFrame(observer);

    value = css.getPropertyValue(property).trim();

    if (value !== initialValue) {
      callback((initialValue = value));
    }
  };

  observer();

  return () => cancelAnimationFrame(frameId);
};

export default observeStyle;
