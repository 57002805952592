const calcHeight = () => {
  const windowHeightCalc = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  windowHeightCalc();

  window.addEventListener('resize', () => {
    windowHeightCalc();
  });

  window
    .matchMedia('(orientation: portrait)')
    .addEventListener('change', () => windowHeightCalc);
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    calcHeight();
  },
  {
    once: true,
  }
);
