import './dropdown.scss';

const selectors: Record<'dropdown' | 'close' | 'trigger' | 'button', string> = {
  dropdown: '[data-dropdown]',
  close: '[data-dropdown-close]',
  trigger: '[data-dropdown-trigger]',
  button: '[data-button]',
};

const classes: Record<'active', string> = {
  active: 'active',
};

const elements: Record<'dropdowns', null | HTMLElement | Element[]> = {
  dropdowns: null,
};

const init = () => {
  elements.dropdowns = Array.from(
    document.querySelectorAll(selectors.dropdown)
  );
};

window.DROPDOWN_INIT = init;

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();

    document.addEventListener('click', (e) => {
      if (elements.dropdowns) {
        //@ts-ignore
        elements.dropdowns.forEach((dropdown) => {
          const button = dropdown.querySelector(selectors.button);
          if (!dropdown.contains(e.target)) {
            dropdown.classList.remove(classes.active);
            button.classList.remove(classes.active);
            return;
          }

          if (dropdown.contains(e.target)) {
            if (dropdown.classList.contains(classes.active)) {
              dropdown.classList.remove(classes.active);
              button.classList.remove(classes.active);
            } else {
              dropdown.classList.add(classes.active);
              button.classList.add(classes.active);
            }
          }
        });
      }
    });
  },
  {
    once: true,
  }
);
