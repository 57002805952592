import './accordion';
import './tab';
import './window-height-calc';

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    console.info('scripts load');
  },
  { once: true }
);
