import './header-mobile.scss';
import { MouseEvent } from 'react';

const selectors: Record<
  | 'header'
  | 'headerMain'
  | 'menuTop'
  | 'navigationItem'
  | 'burger'
  | 'subMenu'
  | 'logo'
  | 'goBack'
  | 'activeSubMenuTitle',
  string
> = {
  header: '[data-header-mobile]',
  headerMain: '[data-header-mobile-main]',
  burger: '[data-header-mobile-burger]',
  menuTop: '[data-header-mobile-menu-top]',
  navigationItem: '[data-header-mobile-navigation-item]',
  subMenu: '[data-header-mobile-submenu]',
  logo: '[data-header-mobile-logo]',
  goBack: '[data-header-mobile-back]',
  activeSubMenuTitle: '[data-header-mobile-active-submenu-title]',
};

const classes: Record<
  | 'isActive'
  | 'isVisibilityHidden'
  | 'isHidden'
  | 'bars'
  | 'close'
  | 'isOverflowHidden',
  string
> = {
  isActive: 'active',
  isHidden: 'is-hidden',
  isVisibilityHidden: 'is-visibility-hidden',
  isOverflowHidden: 'is-overflow-hidden',
  bars: 'fa-bars',
  close: 'fa-xmark',
};

const init = () => {
  const header = document.querySelector(selectors.header) as HTMLElement;
  if (!header) return;
  const burger = header.querySelector(selectors.burger);
  const goBack = header.querySelector(selectors.goBack) as HTMLElement;
  const subMenus = Array.from(header.querySelectorAll(selectors.subMenu));
  const navigationItems = Array.from(
    header.querySelectorAll(selectors.navigationItem)
  );
  const logo = header.querySelector(selectors.logo) as HTMLElement;
  const menuTop = header.querySelector(selectors.menuTop) as HTMLElement;
  const activeSubMenuTitle = header.querySelector(
    selectors.activeSubMenuTitle
  ) as HTMLElement;

  navigationItems.forEach((navigationItem) => {
    navigationItem.addEventListener('click', (ev) => {
      const target = ev.currentTarget as HTMLElement;
      const subMenu = target.nextElementSibling;
      const subMenuClosest = target.closest(selectors.subMenu);

      if (activeSubMenuTitle) {
        activeSubMenuTitle.innerText =
          target.getAttribute('data-header-mobile-navigation-item') || '';
      }
      logo.classList.add(classes.isHidden);
      goBack.classList.remove(classes.isHidden);
      menuTop.classList.add(classes.isVisibilityHidden);

      if (subMenuClosest) {
        subMenuClosest.classList.add(classes.isVisibilityHidden);
      }

      if (subMenu) {
        subMenu.classList.add(classes.isActive);
      }
    });
  });

  if (goBack) {
    goBack.addEventListener('click', () => {
      const activeSubMenus = subMenus.filter((submenu) =>
        submenu.classList.contains(classes.isActive)
      );
      const lastActiveSubMenu = activeSubMenus[activeSubMenus.length - 1];
      const previousActiveSubMenu = activeSubMenus[activeSubMenus.length - 2];

      if (lastActiveSubMenu) {
        lastActiveSubMenu.classList.remove(classes.isActive);
      }

      if (previousActiveSubMenu) {
        activeSubMenuTitle.innerText =
          previousActiveSubMenu.getAttribute('data-header-mobile-submenu') ||
          '';
        previousActiveSubMenu.classList.remove(classes.isVisibilityHidden);
      }

      if (!previousActiveSubMenu && menuTop) {
        activeSubMenuTitle.innerText = '';
        menuTop.classList.remove(classes.isVisibilityHidden);
        logo.classList.remove(classes.isHidden);
        goBack.classList.add(classes.isHidden);
      }
    });
  }

  if (burger) {
    burger.addEventListener('click', (e) => {
      const target = e.currentTarget as HTMLElement;
      const burgerIcon = target.querySelector('i') as HTMLElement;

      if (header.classList.contains(classes.isActive)) {
        header.classList.remove(classes.isActive);
        burgerIcon.classList.replace(classes.close, classes.bars);
        subMenus.forEach((submenu) =>
          submenu.classList.remove(classes.isVisibilityHidden, classes.isActive)
        );
        menuTop.classList.remove(classes.isVisibilityHidden);
        logo.classList.remove(classes.isHidden);
        goBack.classList.add(classes.isHidden);
        activeSubMenuTitle.innerText = '';
        document.body.classList.remove(classes.isOverflowHidden);
        return;
      }

      document.body.classList.add(classes.isOverflowHidden);
      header.classList.add(classes.isActive);
      burgerIcon.classList.replace(classes.bars, classes.close);
    });
  }

  setHeaderMobileMenuOffsetHeight(header);
  new ResizeObserver(() => setHeaderMobileMenuOffsetHeight(header)).observe(
    header
  );
};

const setHeaderMobileMenuOffsetHeight = (header: HTMLElement) => {
  const headerMain = header.querySelector(selectors.headerMain) as HTMLElement;

  document.documentElement.style.setProperty(
    '--header-mobile-menu-offset-height',
    headerMain.getBoundingClientRect().height + 'px'
  );
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
