import './banner';
import './simple-text';
import './hero-home';
import './hero-banner';
import './markets';
import './article-slider';
import './content-cards';
import './text-image';
import './usp';
import './image-gallery';
import './relevant-brands';
import './tabs';
import './card-grid';
import './content-slider';
import './image-grid';
import './demo';
import './logo-slider';
import './title-side';
import './quote';
import './text-image';
import './video';
import './image';
import './hero-full-image';
import './breadcrumbs';
import './map';
import './text-awards';
import './contact-form';
import './news-detail';
import './search-results';
import './policies';
