import './textarea.scss';

const selectors: Record<'textarea', string> = {
  textarea: 'textarea',
};

const init = () => {
  Array.from(document.querySelectorAll(selectors.textarea), (textarea) => {
    textarea.addEventListener('input', (ev) => {
      const target = ev.currentTarget as HTMLElement;
      target.style.height = '5px';
      target.style.height = target.scrollHeight + 'px';
    });
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  { once: true }
);
