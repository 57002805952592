import './article-slider.scss';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

type Selector = 'slider' | 'container';

const selectors: Record<Selector, string> = {
  slider: '.b-article-slider-slider',
  container: '[data-b-article-slider-container]',
};

const elements: Record<Selector, Element | HTMLElement | null> = {
  container: null,
  slider: null,
};

function initSlider() {
  Swiper.use([Navigation]);

  elements.container = document.querySelector(selectors.container);

  if (!elements.container) return null;

  elements.container.setAttribute(
    'style',
    `width: ${window.innerWidth - elements.container.getBoundingClientRect().left + 'px'}`
  );

  const swiper = new Swiper(selectors.slider, {
    slidesPerView: 'auto',
    spaceBetween: 24,
    navigation: {
      nextEl: '.b-article-slider__next',
      prevEl: '.b-article-slider__prev',
      disabledClass: 'hidden',
    },
  });
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initSlider();
  },
  { once: true }
);

addEventListener('resize', () => {
  initSlider();
});
