import './footer.scss';

const selectors: Record<'dropdown' | 'trigger', string> = {
  dropdown: '[data-footer-dropdown]',
  trigger: '[data-footer-dropdown-trigger]',
};

const classes: Record<'active', string> = {
  active: 'active',
};

const mql = window.matchMedia('(min-width: 1024px)');

const init = () => {
  let isDesktop = mql.matches;

  Array.from(document.querySelectorAll(selectors.dropdown), (dropdown) => {
    if (!dropdown) return;

    const trigger = dropdown.querySelector(selectors.trigger);

    if (!trigger) return;
    trigger.addEventListener('click', () => {
      if (isDesktop) return;

      dropdown.classList[
        dropdown.classList.contains(classes.active) ? 'remove' : 'add'
      ](classes.active);
    });
  });

  mql.addEventListener('change', ({ matches }: { matches: boolean }) => {
    isDesktop = matches;
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
