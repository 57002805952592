import './pagination.scss';

type SelectorType =
  | 'current_page'
  | 'total_page'
  | 'replace_id'
  | 'button_prev'
  | 'button_next'
  | 'container'
  | 'list'
  | 'paginationNumbers'
  | 'block_selector';

const selectors: Record<SelectorType, string> = {
  current_page: 'data-pagination-current-page',
  total_page: 'data-pagination-total-pages',
  replace_id: 'data-pagination-replace-id',
  button_prev: '[data-pagination-prev]',
  button_next: '[data-pagination-next]',
  container: '[data-pagination-container]',
  list: '[data-pagination-list]',
  block_selector: 'data-pagination-block-selector',
  paginationNumbers: 'data-pagination-page',
};

const elements: Record<
  'container' | 'list' | 'buttonPrev' | 'buttonNext' | 'page_blocks',
  null | NodeListOf<HTMLElement> | HTMLElement
> = {
  container: null,
  list: null,
  buttonPrev: null,
  buttonNext: null,
  page_blocks: null,
};

const classes: Record<'hidden', string> = {
  hidden: 'hidden',
};

const pageRange: number = 3;

const PAGINATION_SETTINGS: { current_page: number; total_pages: number } = {
  current_page: 1,
  total_pages: 1,
};

type ButtonType = 'prev' | 'next';
const BUTTON_TYPES: Record<ButtonType, string> = {
  prev: 'prev',
  next: 'next',
};

function getPages() {
  let pagesArray: number[] = [];

  const rangeStart = () => {
    const start = PAGINATION_SETTINGS.current_page - pageRange;
    return start > 0 ? start : 1;
  };

  const rangeEnd = () => {
    const end = PAGINATION_SETTINGS.current_page + pageRange;
    return end < PAGINATION_SETTINGS.total_pages
      ? end
      : PAGINATION_SETTINGS.total_pages;
  };

  for (let i = rangeStart(); i <= rangeEnd(); i += 1) {
    if (i !== 1 && i !== PAGINATION_SETTINGS.total_pages) {
      pagesArray.push(i);
    }
  }

  return pagesArray;
}

function generateNumber(
  condition = false,
  label: string | number = '',
  active = false
) {
  if (condition) {
    return `<li class="pagination__item ${active && 'active'} ${label === '...' && 'pagination__item_no-border'}" ${
      label !== '...' && `${selectors.paginationNumbers}='${label}'`
    }><p class="text text_default-18">${label}</p></li>`;
  }
  return '';
}

function setNewPagination(current_page: number | string) {
  PAGINATION_SETTINGS.current_page = Number(current_page);
  initPagination();
}

function toggleViewContent(current_page: number | string) {
  if (!elements.page_blocks) return;

  // @ts-ignore
  Array.from(elements.page_blocks).map((el, index) =>
    el.classList[index + 1 === Number(current_page) ? 'remove' : 'add'](
      classes.hidden
    )
  );
}

function handleClick(e: any, type: string, current_page: number | string) {
  e.stopPropagation();
  e.preventDefault();

  if (type === BUTTON_TYPES.prev) {
    if (PAGINATION_SETTINGS.current_page !== 1) {
      const new_current_page = PAGINATION_SETTINGS.current_page - 1;

      toggleViewContent(new_current_page);
      return setNewPagination(new_current_page);
    }
  } else if (type === BUTTON_TYPES.next) {
    if (PAGINATION_SETTINGS.current_page !== PAGINATION_SETTINGS.total_pages) {
      const new_current_page = PAGINATION_SETTINGS.current_page + 1;

      toggleViewContent(new_current_page);
      return setNewPagination(new_current_page);
    }
  } else {
    toggleViewContent(current_page);
    return setNewPagination(current_page);
  }
}

const initPagination = () => {
  elements.container = document.querySelector(
    selectors.container
  ) as HTMLElement;

  if (elements.container) {
    elements.list = document.querySelector(selectors.list) as HTMLElement;
    elements.page_blocks = document.querySelectorAll(
      elements.container.getAttribute(selectors.block_selector) as string
    );

    // this is necessary in order to avoid double events on the button
    if (!elements.buttonPrev) {
      elements.buttonPrev = document.querySelector(
        selectors.button_prev
      ) as HTMLElement;
      elements.buttonPrev.addEventListener(
        'click',
        (e) => handleClick(e, BUTTON_TYPES.prev, 1),
        false
      );
    }

    // this is necessary in order to avoid double events on the button
    if (!elements.buttonNext) {
      elements.buttonNext = document.querySelector(
        selectors.button_next
      ) as HTMLElement;
      elements.buttonNext.addEventListener(
        'click',
        (e: any) => handleClick(e, BUTTON_TYPES.next, 0),
        false
      );
    }

    PAGINATION_SETTINGS.total_pages =
      Number(elements.container.getAttribute(selectors.total_page)) || 1;

    if (PAGINATION_SETTINGS.current_page > 1) {
      elements.buttonPrev
        // @ts-ignore
        .querySelector('a')
        .classList.remove(classes.hidden);
    }
    if (PAGINATION_SETTINGS.current_page === 1) {
      elements.buttonPrev
        // @ts-ignore
        .querySelector('a')
        .classList.add(classes.hidden);
    }
    if (PAGINATION_SETTINGS.current_page < PAGINATION_SETTINGS.total_pages) {
      elements.buttonNext
        // @ts-ignore
        .querySelector('a')
        .classList.remove(classes.hidden);
    }
    if (PAGINATION_SETTINGS.current_page === PAGINATION_SETTINGS.total_pages) {
      elements.buttonNext
        // @ts-ignore
        .querySelector('a')
        .classList.add(classes.hidden);
    }

    const pages = getPages();

    elements.list.innerHTML = `
      ${generateNumber(true, 1, PAGINATION_SETTINGS.current_page === 1)}
      ${generateNumber(PAGINATION_SETTINGS.current_page - pageRange + 1 > pageRange, '...')}
      ${pages.map((item, key) => generateNumber(true, item, PAGINATION_SETTINGS.current_page === item)).join('')}
      ${generateNumber(PAGINATION_SETTINGS.current_page + pageRange < PAGINATION_SETTINGS.total_pages - pageRange + 2, '...')}
      ${generateNumber(PAGINATION_SETTINGS.total_pages > 1, PAGINATION_SETTINGS.total_pages, PAGINATION_SETTINGS.current_page === PAGINATION_SETTINGS.total_pages)}
    `;
    Array.from(
      document.querySelectorAll(`[${selectors.paginationNumbers}]`)
    ).map((i) =>
      i.addEventListener('click', (e) =>
        // @ts-ignore
        handleClick(e, '', i.getAttribute(selectors.paginationNumbers))
      )
    );
  }
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initPagination();
  },
  { once: true }
);
