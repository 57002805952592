import './text-awards.scss';

type Selector = 'cards' | 'button';
type Classes = 'hidden';

const selectors: Record<Selector, string> = {
  cards: '[data-text-awards-hidden-card]',
  button: '[data-text-awards-button-show-more]',
};

const elements: Record<Selector, Element | NodeListOf<Element> | null> = {
  cards: null,
  button: null,
};

const classes: Record<Classes, string> = {
  hidden: 'hidden',
};

function init() {
  elements.cards = document.querySelectorAll(selectors.cards);
  elements.button = document.querySelector(selectors.button);

  if (!elements.cards || !elements.button) return null;

  const cards: Element[] = Array.from(elements.cards);

  if (elements.button) {
    elements.button.addEventListener('click', (e) => {
      const target = e.currentTarget as HTMLElement;

      cards.map((el) => el.classList.remove(classes.hidden));
      target.classList.add(classes.hidden);
    });
  }
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  { once: true }
);

export {};
