import './desktop-submenu-slider.scss';
import observeStyle from '../../../../../utils/observe-style';
import Swiper from 'swiper';
import { Autoplay, Navigation, EffectFade } from 'swiper/modules';

const selectors: Record<
  'block' | 'swiper' | 'next' | 'timeline' | 'card' | 'count',
  string
> = {
  block: '[data-desktop-submenu-slider]',
  swiper: '[data-desktop-submenu-slider-swiper]',
  next: '[data-slider-navigation-button-next]',
  timeline: '[data-slider-timer]',
  card: '[data-desktop-submenu-slider-card]',
  count: '[data-desktop-submenu-slider-swiper-count]',
};

const DURATION = 5000;

const init = () => {
  Array.from(document.querySelectorAll(selectors.block), (block) => {
    const swiper = block.querySelector(selectors.swiper) as HTMLElement;
    const next = block.querySelector(selectors.next) as HTMLElement;
    const timeline = block.querySelector(selectors.timeline) as HTMLElement;
    const card = block.querySelector(selectors.card) as HTMLElement;
    const count = block.querySelector(selectors.count) as HTMLElement;
    const parentSubMenu = block.closest('.desktop-submenu') as HTMLElement;

    const dataItems = JSON.parse(
      block.getAttribute('data-desktop-submenu-slider') || '{}'
    );

    const slider = new Swiper(swiper, {
      modules: [EffectFade, Autoplay, Navigation],
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: DURATION,
        disableOnInteraction: false,
      },
      spaceBetween: 0,
      slidesPerView: 1,
      navigation: {
        nextEl: next,
      },
      on: {
        realIndexChange: (instance) => {
          count.innerText = (instance.realIndex + 1).toString();
          changeCardData({ card, data: dataItems[instance.realIndex] });
        },
        autoplayTimeLeft: (swiper, timeLeft, percentage) => {
          timeline.style.width = 100 * (1 - percentage) + '%';
        },
      },
    });

    observeStyle(
      parentSubMenu,
      '--desktop-submenu-status',
      (value: 'closed' | 'opened') => {
        if (value === 'closed') {
          console.log('paused');
          slider.autoplay.stop();
        }

        if (value === 'opened') {
          console.log('opened');
          slider.autoplay.start();
        }
      }
    );
  });
};

type ImageProps = {
  src: string;
  alt: string;
};

interface changeCardDataProps {
  card: HTMLElement;
  data: {
    heading: string;
    logo: ImageProps;
    image: ImageProps;
  };
}

function changeCardData({ card, data }: changeCardDataProps) {
  const cardImage = card.querySelector(
    '.desktop-submenu-slider-card__image'
  ) as HTMLElement;
  const cardLogo = card.querySelector(
    '.desktop-submenu-slider-card__logo'
  ) as HTMLElement;
  const cardHeading = card.querySelector(
    '.desktop-submenu-slider-card__heading'
  ) as HTMLElement;

  const emptyImage =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAQSURBVHgBAQUA+v8AAAAAAAAFAAFkeJU4AAAAAElFTkSuQmCC';

  cardImage.setAttribute('src', data.image.src || emptyImage);
  cardImage.setAttribute('alt', data.image.alt);

  cardLogo.setAttribute('src', data.logo.src || emptyImage);
  cardLogo.setAttribute('alt', data.logo.alt);

  cardHeading.innerText = data.heading;
}

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
