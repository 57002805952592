export const initTabs = () => {
  const selectors: Record<'buttons', string> = {
    buttons: '[data-tab-index]',
  };

  const elements: Record<'buttons', NodeListOf<HTMLElement> | null> = {
    buttons: null,
  };

  const queryElements = () => {
    elements.buttons = document.querySelectorAll(selectors.buttons);
    registerEvents();
    Array.from(elements.buttons)
      .reduce((acc, item) => {
        const key: string | undefined = item.dataset.tabKey;

        if (!key) return acc;
        if (acc.includes(key)) return acc;

        return [...acc, key];
      }, [] as string[])
      .forEach((filterKey) => {
        const buttons = Array.from(elements.buttons || []).filter(
          (el) => el.dataset.tabKey === filterKey
        );

        buttons[0].click();
      });
  };

  const handleButtonClick = (e: any) => {
    const key = e.currentTarget.dataset.tabKey;
    const index = e.currentTarget.dataset.tabIndex;
    const buttonsGroup = document.querySelectorAll(
      `[data-tab-key=${key}]`
    ) as NodeListOf<HTMLElement>;
    const contentsGroup = document.querySelectorAll(
      `[data-tab-content-key=${key}]`
    ) as NodeListOf<HTMLElement>;

    Array.from(buttonsGroup).map((button) => button.classList.remove('active'));
    e.currentTarget.classList.add('active');

    if (index === '*') {
      Array.from(contentsGroup).map(
        (content) => (content.style.display = 'block')
      );
      return null;
    }

    Array.from(contentsGroup).forEach((content) => {
      const isFind =
        content.dataset.tabContentIndex &&
        (content.dataset.tabContentIndex.split(',').includes(index) as boolean);

      if (isFind) {
        content.style.display = 'block';
      } else {
        content.style.display = 'none';
      }
    });
  };

  const registerEvents = () => {
    if (!elements.buttons) return null;

    Array.from(elements.buttons).map((button) =>
      button.addEventListener('click', handleButtonClick)
    );
  };

  queryElements();
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    initTabs();
  },
  {
    once: true,
  }
);
