import './video.scss';
import Plyr from 'plyr';
import prettyMilliseconds from 'pretty-ms';

const selectors: Record<
  'block' | 'player' | 'playButton' | 'duration',
  string
> = {
  block: '[data-video]',
  player: '[data-video-player]',
  playButton: '[data-video-play-button]',
  duration: '[data-video-play-button-duration]',
};

const classes: Record<'isHidden', string> = {
  isHidden: 'is-hidden',
};

const init = () => {
  Array.from(document.querySelectorAll(selectors.block), (block) => {
    const player = block.querySelector(selectors.player) as HTMLElement;
    const playButton = block.querySelector(selectors.playButton) as HTMLElement;
    const instance = new Plyr(player, {});

    instance.on('playing', () => {
      playButton.classList.add(classes.isHidden);
    });

    instance.on('pause', () => {
      playButton.classList.remove(classes.isHidden);
    });

    instance.on('ready', () => {
      const duration = block.querySelector(selectors.duration) as HTMLElement;

      duration.innerText = prettyMilliseconds(instance.duration * 1000, {
        colonNotation: true,
      });
    });

    playButton.addEventListener('click', () => {
      instance.play();
    });
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
