import './marquee.scss';

const selectors: Record<'marquee' | 'container' | 'group', string> = {
  marquee: '[data-marquee]',
  container: '[data-marquee-container]',
  group: '[data-marquee-group]',
};

const init = () => {
  Array.from(document.querySelectorAll(selectors.marquee), (marquee) => {
    const duration: number =
      Number((marquee as HTMLElement).getAttribute('data-marquee-duration')) ||
      60;
    const container = marquee.querySelector(selectors.container) as HTMLElement;
    const group = marquee.querySelector(selectors.group) as HTMLElement;

    const marqueeWidth = marquee.getBoundingClientRect().width;
    const groupWidth = group.getBoundingClientRect().width;
    const count = Math.ceil(marqueeWidth / groupWidth) || 1;

    container.innerHTML = '';

    (marquee as HTMLElement).style.setProperty(
      '--marquee-duration',
      duration / count + 's'
    );

    for (let i = 0; i <= count; i++) {
      container.appendChild(group.cloneNode(true));
    }
  });
};

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);

window.addEventListener('resize', () => {
  init();
});
