type Selector = "accordion" | "trigger";
type Class = "isOpen";

const selectors: Record<Selector, string> = {
  accordion: "[data-accordion]",
  trigger: "[data-accordion-trigger]",
};

const classes: Record<Class, string> = {
  isOpen: "active",
};

const elements: Record<Selector, NodeListOf<HTMLElement> | null> = {
  accordion: null,
  trigger: null,
};

const accordionInit = () => {
  elements.accordion = document.querySelectorAll(selectors.accordion);

  if (!elements.accordion) return null;

  Array.from(elements.accordion).map((element) => {
    const trigger = element.querySelector(selectors.trigger) as HTMLElement;

    if (!trigger) return null;
    trigger.addEventListener("click", () =>
      element.classList[element.classList.contains(classes.isOpen) ? "remove" : "add"](
        classes.isOpen
      )
    );
  });
};

document.addEventListener(
  "DOMContentLoaded",
  async function () {
    accordionInit();
  },
  {
    once: true,
  }
);
