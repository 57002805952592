import './map.scss';
import { Loader } from 'google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { style } from './map-theme';
import { MarkerClustererOptions } from '@googlemaps/markerclusterer/dist/markerclusterer';

export interface MapCardProps {
  id: string | number;
  name: string;
  tags: string[];
  address: string;
  location: google.maps.LatLngLiteral;
  phone?: string;
  website?: string;
  mail?: string;
}

interface MarkerProps extends google.maps.Marker {
  id: string | number;
}

interface ElementInterface {
  api: string;
  items: MapCardProps[];
  markers: google.maps.Marker[];
  clusters: MarkerClusterer | undefined;
  map?: google.maps.Map<HTMLElement>;
  filters: {
    [key: string]: any;
  };
}

const selectors: Record<
  'block' | 'map' | 'card' | 'form' | 'viewAll' | 'item' | 'list' | 'loading',
  string
> = {
  block: '[data-map]',
  map: '[data-map-instance]',
  card: '[data-map-card]',
  form: '[data-map-form]',
  viewAll: '[data-map-view-all]',
  item: '[data-map-item]',
  list: '[data-map-list]',
  loading: '[data-map-loading]',
};

const elements: ElementInterface = {
  api: '',
  items: [],
  markers: [],
  filters: {},
  map: undefined,
  clusters: undefined,
};

const classes: Record<'isActive' | 'isHidden', string> = {
  isActive: 'active',
  isHidden: 'is-hidden',
};

const init = () => {
  const block = document.querySelector(selectors.block);

  if (!block) return;

  const map = block.querySelector(selectors.map) as HTMLElement;
  const form = block.querySelector(selectors.form);
  const viewAll = block.querySelector(selectors.viewAll) as HTMLElement;
  const list = block.querySelector(selectors.list) as HTMLElement;
  const loading = block.querySelector(selectors.loading) as HTMLElement;
  const { items, defaultVisibleCards, api } = window.BLOCK_MAP;

  elements.api = api;
  elements.items = items;

  initGoogleMap(map).then(() => {
    elements.clusters = new MarkerClusterer({
      map: elements.map,
      markers: elements.markers,
    });
  });

  list.addEventListener('click', (e) => {
    const cards = list.querySelectorAll(selectors.card);
    cards.forEach((card) => {
      //@ts-ignore
      if (card.contains(e.target)) {
        const id = card.getAttribute('data-map-card');

        if (card.classList.contains(classes.isActive)) {
          card.classList.remove(classes.isActive);
          getMapBounds(elements.items);
          return;
        }

        card.classList.add(classes.isActive);

        getMapBounds(elements.items.filter((item) => item.id === id));
      } else {
        card.classList.remove(classes.isActive);
      }
    });
  });

  if (viewAll) {
    viewAll.addEventListener('click', () => {
      viewAll.classList.add(classes.isHidden);
      Array.from(block.querySelectorAll(selectors.item), (item) =>
        item.classList.remove(classes.isHidden)
      );
    });
  }

  if (form) {
    form.addEventListener('submit', handleForm(list, loading, viewAll));
    form.addEventListener('change', handleForm(list, loading, viewAll));
  }

  window.addEventListener('resize', () => {
    google.maps.event.trigger(elements.map, 'resize');
  });
};

const handleForm =
  (list: HTMLElement, loading: HTMLElement, viewAll: HTMLElement) =>
  (ev: Event) => {
    ev.preventDefault();
    const target = ev.currentTarget as HTMLFormElement;

    Array.from(target.elements, (element) => {
      //@ts-ignore
      const { name, value, type, checked } = element;

      if (type === 'text') {
        elements.filters[name] = value;
      }

      if (type === 'radio' && checked) {
        elements.filters[name] = value;
      }

      if (type === 'checkbox') {
        elements.filters[name] = elements.filters[name] || [];

        if (checked && !elements.filters[name]?.includes(value)) {
          elements.filters[name] = [...elements.filters[name], value];
        }
      }
      if (['submit', 'range', 'button'].includes(type)) return;
    });

    const targetElement = ev.target as HTMLFormElement;

    if (targetElement.type === 'checkbox') {
      if (
        !targetElement.checked &&
        elements.filters[targetElement.name]?.includes(targetElement.value)
      ) {
        elements.filters[targetElement.name] = elements.filters[
          targetElement.name
        ].filter((v: string) => v !== targetElement.value);
      }
    }

    fetchItems(list, loading, viewAll);
  };

export async function httpRequest<T>(
  request: RequestInfo,
  payload: string
): Promise<T> {
  const response = await fetch(request, { method: 'POST', body: payload });
  return await response.json();
}

const fetchItems = async (
  list: HTMLElement,
  loading: HTMLElement,
  viewAll: HTMLElement
) => {
  loading.classList.add(classes.isActive);
  list.classList.add(classes.isHidden);

  const { items } = await httpRequest<{ items: MapCardProps[] }>(
    elements.api,
    JSON.stringify(elements.filters)
  );

  loading.classList.remove(classes.isActive);
  list.classList.remove(classes.isHidden);

  elements.items = items;

  list.innerHTML = elements.items
    .map((item, index) => renderCard(item, index + 1 > 3))
    .join('');

  viewAll.classList[items.length <= 3 ? 'add' : 'remove'](classes.isHidden);

  getMapBounds(elements.items);
};

const getMapBounds = (items: MapCardProps[]) => {
  const bounds = new google.maps.LatLngBounds();
  const icon = {
    path: faLocationDot.icon[4] as string,
    fillColor: '#2F80ED',
    fillOpacity: 1,
    anchor: new google.maps.Point(
      faLocationDot.icon[0] / 2,
      faLocationDot.icon[1]
    ),
    strokeWeight: 1,
    strokeColor: '#2F80ED',
    scale: 0.075,
  };

  elements.markers.forEach((marker) => marker.setMap(null));
  elements.markers = [];
  elements.clusters?.clearMarkers();

  for (let i = 0; i < items.length; i++) {
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(
        Number(items[i].location.lat),
        Number(items[i].location.lng)
      ),
      map: elements.map,
      icon,
    });

    marker.set('id', items[i].id);
    const position = marker.getPosition();

    //@ts-ignore
    elements.markers.push(marker);

    if (position) {
      bounds.extend(position);
    }
  }

  elements.clusters?.addMarkers(elements.markers);
  if (elements.map) {
    elements.map.fitBounds(bounds);

    if (items.length === 1) {
      elements.map.setZoom(10);
    }
  }
};

const initGoogleMap = async (instance: HTMLElement) => {
  const loader = new Loader('AIzaSyAoJMNX3QJhI0YZaBMVCxgbQBs8dY2A00U', {});
  const google = await loader.load();

  elements.map = new google.maps.Map(instance, {
    styles: style,
    disableDefaultUI: true,
  });

  getMapBounds(elements.items);
};

const renderCard = (data: MapCardProps, isHidden: boolean) => `
  <li data-map-item class="map__item  ${isHidden ? 'is-hidden' : ''}">
    <div class="map-card" data-map-card="${data.id}">
      <div class="map-card__head">
        <i class="icon icon--size-medium fa-solid fa-location-dot" aria-hidden="true"></i>
        <div class="map-card__head-name heading-300">${data.name || ''}</div>
      </div>
      <ul class="map-card__tags">
      ${data.tags
        .map(
          (tag) => `<li>
          <div class="tag brand-light light">
            <span class="body-300">${tag}</span>
          </div>
        </li>`
        )
        .join('')}
      </ul>
      <div class="map-card__content">
        <ul class="map-card__content-list">
        ${
          data.address
            ? `
                <li>
                  <div class="map-card__content-item">
                    <i class="icon icon--size-small fa-solid fa-house" aria-hidden="true"></i>
                    <span class="body-300">${data.address || ''}</span>
                  </div>
                </li>
              `
            : ''
        }
          ${
            data.phone
              ? `
                  <li>
                    <a class="map-card__content-item map-card__content-item--link" href="tel:${data.phone}">
                      <i class="icon icon--size-small fa-solid fa-phone" aria-hidden="true"></i>
                      <span class="body-300">${data.phone}</span>
                    </a>
                  </li>
                `
              : ''
          }
          
          ${
            data.website
              ? `
                  <li>
                    <a class="map-card__content-item map-card__content-item--link" href="${data.website}">
                      <i class="icon icon--size-small fa-solid fa-globe" aria-hidden="true"></i>
                      <span class="body-300">${data.website}</span>
                    </a>
                  </li>
                `
              : ''
          }
          
          ${
            data.mail
              ? `
                <li>
                  <a class="map-card__content-item map-card__content-item--link" href="mailto:${data.mail}">
                    <i class="icon icon--size-small fa-solid fa-envelope" aria-hidden="true"></i>
                    <span class="body-300">${data.mail}</span>
                  </a>
                </li>
              `
              : ''
          }
        </ul>
      </div>
    </div>
</li>
`;

document.addEventListener(
  'DOMContentLoaded',
  async function () {
    init();
  },
  {
    once: true,
  }
);
